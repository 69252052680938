import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { ComicData } from '../shared/store.data';
import { StoreService } from '../shared/store.service';

export interface DataItem {
  basePath: string;
  maxPics: number;
  title: string;
}

@Component({
  selector: 'app-comics-stuff',
  templateUrl: './comics-stuff.component.html',
  styleUrls: ['../comics.component.sass']
})
export class ComicsStuffComponent implements OnInit {
    comicKey: number;
    comicsObj: DataItem;
    comics = [];
    comicName: string;

    constructor(
        private actRoute: ActivatedRoute,
        private storeServ: StoreService
    ) {}


    ngOnInit() {
        const comicsList = this.storeServ.getComics();
        this.comicKey = +this.actRoute.snapshot.paramMap.get('id');
        console.log('comicKey', this.comicKey);
        this.comicsObj = comicsList[this.comicKey];
        const path = this.comicsObj.basePath;
        const maxPics = this.comicsObj.maxPics;
        this.comicName = this.comicsObj.title;
        console.log('comicData', ComicData);
        console.log('comicObj', this.comicsObj);
        this.comics = this.storeServ.buildList(path, maxPics);
        console.log('comics', this.comics);
    }
}
