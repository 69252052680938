import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ComicsDetailComponent } from '../app/comics/comics-detail/comics-detail.component';
import { ComicsComponent } from './comics/comics.component';
import { ComicsListComponent } from './comics/comics-list/comics-list.component';
import { ComicsStuffComponent } from './comics/comics-stuff/comics-stuff.component';

@NgModule({
  declarations: [
    AppComponent,
    // ComicsDetailComponent,
    ComicsComponent,
    ComicsListComponent,
    ComicsStuffComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
