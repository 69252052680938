import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comics',
  templateUrl: './comics.component.html',
  styleUrls: ['./comics.component.sass']
})
export class ComicsComponent implements OnInit {
  title = 'frog';
  titleImg = '../../assets/imgs/title000.png';
  bannerImg = '../../assets/imgs/banner.png';
  viewClass: string;
  constructor(
  ) {}

  ngOnInit() {
  }
}
