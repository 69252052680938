import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComicsListComponent } from './comics/comics-list/comics-list.component';
import { ComicsDetailComponent } from './comics/comics-detail/comics-detail.component';
import { ComicsStuffComponent } from './comics/comics-stuff/comics-stuff.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'comics-list' },
  { path: 'comics-list', component: ComicsListComponent },
  { path: 'comics-detail', component: ComicsDetailComponent},
  { path: 'comics-detail/:id', component: ComicsDetailComponent},
  { path: 'comics-stuff', component: ComicsStuffComponent},
  { path: 'comics-stuff/:id', component: ComicsStuffComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
