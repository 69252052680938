import { Component, OnInit } from '@angular/core';
import { StoreService } from '../shared/store.service';

@Component({
  selector: 'app-comics-list',
  templateUrl: './comics-list.component.html',
  styleUrls: ['../comics.component.sass']
})
export class ComicsListComponent implements OnInit {
  title = 'frog';
  panel001 = '../../assets/imgs/panel001sm.jpg';
  panel002 = '../../assets/imgs/panel002.jpg';
  comics = [];

  constructor(
    private storeServ: StoreService
  ) {}

  ngOnInit() {
    this.comics = this.storeServ.getComics();
    console.log('comics', this.comics);
    this.comics.forEach((val) => {
      console.log('comic', val);
    });

  }
}
