import { Injectable } from '@angular/core';
import ComicsData from '../../../assets/data/comics.json';
import { ComicsComponent } from '../comics.component.js';
import { ComicsDetailComponent } from '../comics-detail/comics-detail.component.js';



@Injectable({
  providedIn: 'root'
})
export class StoreService {

    comicsList: any;

    constructor() {
      this.comicsList = ComicsData;
    }

    getComics() {
      return this.comicsList;
    }

    buildList(basePath: string, maxPics: number) {
      const picList = [];
      for (let index = 1; index <= maxPics; index++) {
        if (index < 10) {
          picList.push(basePath + '/panel00' + index + '.jpg');
        } else if (index < 100) {
          picList.push(basePath + '/panel0' + index + '.jpg');
        } else if (index < 1000) {
          picList.push(basePath + '/panel' + index + '.jpg');
        }
      }
      return picList;
    }
}
