import { Component, OnInit, Input } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
// import { ComicData } from '../shared/store.data';
// import { StoreService } from '../shared/store.service';


export interface DataItem {
  basePath: string;
  maxPics: number;
  title: string;
}

Component({
  selector: 'app-comics-detail',
  templateUrl: './comics-detail.component.html',
  styleUrls: ['../comics.component.sass']
});
export class ComicsDetailComponent implements OnInit {
  title = 'frog';
  imgPath = '../../assets/imgs/philosophy/';
  panel001 = 'panel001.jpg';
  panel002 = 'panel002.jpg';
  comics = [];
  comicName = '';
  deviceInfo = null;
  viewClass = '';

  comicsObj: DataItem;
  comicKey: string;

  constructor(
    private actRoute: ActivatedRoute,
    // private storeServ: StoreService
  ) {}

  ngOnInit() {
    this.comicKey = this.actRoute.snapshot.paramMap.get('id');
    // this.comicsObj = ComicData[this.comicKey];
    // const path = this.comicsObj.basePath;
    // const maxPics = this.comicsObj.maxPics;
    // this.comics = this.storeServ.buildList(path, maxPics);
    // this.comicName = this.comicsObj.title;
    // this.viewClass = this.deviceDetect.detectDevice();
    // console.log('viewClass', this.viewClass);

  }
}
